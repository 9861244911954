<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <estimate-form
      v-else
      :formErrors="formErrors"
      :estimate-data="estimate"
      :loading="loading"
      @onEditEstimate="onEditEstimate"
      @onViewEstimate="onViewEstimate"
      @onCloseEstimateModal="onCloseEstimateModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultEstimation from "../defaultEstimation";
import EstimateForm from "../partials/EstimateForm.vue";
import locationMixin from "@/mixins/location-mixin";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    EstimateForm,
  },

  mixins: [alertLeave, locationMixin],

  data() {
    const estimateData = cloneDeep(defaultEstimation);
    // estimateData = this.$fillUserOrganizationData(estimateData);
    estimateData.vehicle_vin = this.$route.query.vehicle_vin ?? null;
    // estimateData.vehicle.id = this.$route.query.vehicle_id ?? null;
    estimateData.inspection.id = this.$route.query.inspection_id ?? null;
    estimateData.customer.id = this.$route.query.customer_id ?? null;
    // estimateData.estimate.id = this.$route.query.estimate_id;

    if (this.globalOrganization) {
      estimateData.organization = this.globalOrganization;
    }

    if (this.globalLocation) {
      estimateData.allowedLocations.push(this.globalLocation);
    }

    return {
      estimate: estimateData,
      formErrors: null,
      loading: false,
    };
  },

  async created() {
    if (this.$route.query.vehicle_id) {
      await this.getVehicle(this.$route.query.vehicle_id);
    }
  },

  methods: {
    onEditEstimate(estimate) {
      this.$emit("onEditEstimate", estimate);
    },

    async getVehicle(vehicleId) {
      this.loading = true;
      try {
        await this.$store.dispatch("vehicles/get", vehicleId);
        const vehicle = await this.$store.getters["vehicles/vehicle"];
        this.estimate.vehicle = vehicle;
        this.estimate.organization = vehicle.organization;
        this.estimate.allowedLocations = vehicle.allowedLocations;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    onViewEstimate(estimate) {
      this.$emit("onViewEstimate", estimate);
    },
    onCloseEstimateModal() {
      this.$emit("onCloseEstimateModal");
    },
  },
};
</script>
