import { ESTIMATE_ITEM_CONDITION_TO_REPLACE } from "@/constants/estimateItems";

export default {
  type: "estimation-items",
  name: "",
  status: "",
  excerpt: "",
  // component_code: "",
  // component_name: "",
  // component_amount: 0,
  // component_quantity: 0,
  component_condition: "",
  component_details: "",
  repair_time_hour: 0,
  work_amount: 0,
  components: [
    {
      component_code: "",
      component_name: "",
      component_amount: 0,
      component_quantity: 0,
    },
  ],
  relationshipNames: ["estimation", "organization"],
  estimation: {
    type: "estimations",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
};
