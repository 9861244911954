<template>
  <div class="add-inspection-steps">
    <div class="add-inspection-steps-header">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step
          :title="$t('COMMON.VEHICLE')"
          @click.native="switchToStep(VEHICLE_STEP)"
          class="cursor-pointer"
        >
        </el-step>
        <el-step
          :title="$t('COMMON.CUSTOMER')"
          @click.native="switchToStep(CUSTOMER_STEP)"
          :class="{ 'cursor-pointer': canPassVehicleStep }"
        ></el-step>
        <el-step
          title="Validation"
          v-if="this.$currentUserCan(this.$permissions.PERM_VALID_ESTIMATIONS)"
          @click.native="switchToStep(VALIDATION_STEP)"
          :class="{ 'cursor-pointer': canPassCustomerStep }"
        ></el-step>
      </el-steps>
    </div>
    <div class="add-inspection-steps-content">
      <div v-if="active === VEHICLE_STEP" class="steps-content-1">
        <div class="step-content-body">
          <estimate-form-vehicle-step
            :estimate-data.sync="estimate"
            :form-errors="formErrors"
            :disabled="!canEditEstimate(estimate)"
            @onViewEstimate="onViewEstimate"
            @onCloseEstimateModal="onCloseEstimateModal"
          />
        </div>
        <div class="steps-content-footer">
          <Button
            v-if="canCancelEstimate(estimate)"
            class="cancel"
            @click="showCancelModal = true"
          >
            {{ $t("ESTIMATES.CANCEL_ESTIMATE") }}
          </Button>
          <Button
            class="next"
            @click="passVehicleStep"
            :disabled="!canPassVehicleStep"
            :class="{ 'opacity-5 cursor-not-allowed': !canPassVehicleStep }"
          >
            <i class="fas fa-spinner fa-spin mr-1" v-if="isLoading"></i>
            {{ $t("COMMON.NEXT") }}
            <i class="fal fa-long-arrow-right"></i>
          </Button>
          <Button
            class="prev"
            @click="onViewEstimate()"
            v-if="canViewEstimate(estimate)"
          >
            {{ $t("ESTIMATES.SHOW_ESTIMATE") }}
          </Button>
        </div>
      </div>

      <div v-if="active === CUSTOMER_STEP" class="steps-content-2">
        <div class="step-content-body">
          <customer-step-form-component
            :object-data.sync="estimate"
            :form-errors="formErrors"
            :disabled="!canEditEstimate(estimate)"
          />
        </div>
        <div class="steps-content-footer">
          <Button
            v-if="canCancelEstimate(estimate)"
            class="cancel"
            @click="showCancelModal = true"
            >{{ $t("ESTIMATES.CANCEL_ESTIMATE") }}</Button
          >
          <Button class="prev" @click="prev">
            <i class="fal fa-long-arrow-left"></i>
            {{ $t("COMMON.PREVIOUS") }}
          </Button>
          <Button
            class="next"
            @click="passCustomerStep"
            :disabled="!canPassCustomerStep"
            :class="{ 'opacity-5 cursor-not-allowed': !canPassCustomerStep }"
          >
            <i class="fas fa-spinner fa-spin mr-1" v-if="isLoading"></i>
            {{ $t("COMMON.NEXT") }}
            <i class="fal fa-long-arrow-right"></i>
          </Button>
          <Button
            class="prev"
            @click="onViewEstimate()"
            v-if="canViewEstimate(estimate)"
          >
            {{ $t("ESTIMATES.SHOW_ESTIMATE") }}
          </Button>
        </div>
      </div>

      <div v-if="active === VALIDATION_STEP" class="steps-content-3">
        <div class="step-content-body">
          <estimate-form-validation-step :estimate-data.sync="estimate" />
        </div>
        <div class="steps-content-footer">
          <Button
            v-if="canCancelEstimate(estimate)"
            class="cancel"
            @click="showCancelModal = true"
          >
            {{ $t("ESTIMATES.CANCEL_ESTIMATE") }}
          </Button>
          <Button class="prev" @click="prev">
            <i class="fal fa-long-arrow-left"></i>
            {{ $t("COMMON.PREVIOUS") }}
          </Button>

          <Button class="next" @click="close" v-if="canPassValidateStep">
            {{ $t("COMMON.CLOSE") }}
          </Button>

          <Button class="validate" @click="showValidateModal = true" v-else>
            <i class="fas fa-spinner fa-spin mr-1" v-if="isLoading"></i>
            {{ $t("COMMON.VALIDATE") }}
          </Button>
          <Button
            class="prev"
            @click="onViewEstimate()"
            v-if="canViewEstimate(estimate)"
          >
            {{ $t("ESTIMATES.SHOW_ESTIMATE") }}
          </Button>
        </div>
      </div>
    </div>

    <inspection-confirmation-modal
      :confirm-action="cancelEstimate"
      :confirm-button-text="$t('COMMON.YES_CANCEL')"
      :isLoading="isLoading"
      :message="$t('ESTIMATES.CANCEL_THIS_ESTIMATE')"
      :open.sync="showCancelModal"
      modal-classes="modal-secondary cancel"
    />

    <inspection-confirmation-modal
      :confirm-action="ValidateEstimate"
      :confirm-button-text="$t('COMMON.YES_VALIDATE')"
      :isLoading="isLoading"
      :message="$t('ESTIMATES.VALIDATE_THIS_ESTIMATE')"
      :open.sync="showValidateModal"
      modal-classes="modal-secondary validate"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeaveMixin from "@/mixins/alert-leave-mixin";
import { Checkbox, CheckboxGroup, Radio, Step, Steps } from "element-ui";
import EstimateFormVehicleStep from "./EstimateFormVehicleStep.vue";
import EstimateFormValidationStep from "./EstimateFormValidationStep.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import swal from "sweetalert2";
import {
  CUSTOMER_TYPE_COMPANY,
  CUSTOMER_TYPE_INDIVIDUAL,
} from "@/constants/customers";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import { ESTIMATE_STATUS_DRAFT } from "@/constants/estimates";
import CustomerStepFormComponent from "@/components/CustomerStepFormComponent.vue";

export default {
  layout: "DashboardLayout",

  name: "estimate-form",

  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Radio.name]: Radio,
    EstimateFormVehicleStep,
    EstimateFormValidationStep,
    InspectionConfirmationModal,
    CustomerStepFormComponent,
  },

  mixins: [alertLeaveMixin, requestErrorMixin, estimatePermissionsMixin],

  props: ["estimateData", "loading"],

  data() {
    const estimate = cloneDeep(this.estimateData);
    const estimateBackup = cloneDeep(this.estimateData);
    const VEHICLE_STEP = 0;
    const CUSTOMER_STEP = 1;
    const VALIDATION_STEP = 2;
    const isLoading = this.loading ?? false;
    return {
      VEHICLE_STEP,
      CUSTOMER_STEP,
      VALIDATION_STEP,
      estimate,
      formErrors: null,
      isLoading,
      active: 0,
      showCancelModal: false,
      showValidateModal: false,
      estimateBackup,
    };
  },

  created() {
    this.setActiveStep();
  },

  computed: {
    canPassVehicleStep() {
      return !this.isLoading /* && this.$idExist(this.estimate.vehicle?.id) */;
    },

    canPassCustomerStep() {
      return (
        this.canPassVehicleStep &&
        (this.$idExist(this.estimate.customer?.id) ||
          this.$idExist(this.estimate.approver?.id) ||
          !!this.estimate.customer?.organization)
      );
    },

    canPassValidateStep() {
      return (
        this.canPassCustomerStep &&
        this.estimate.status != ESTIMATE_STATUS_DRAFT
      );
    },

    activeStep() {
      let active;
      if (this.canPassCustomerStep) {
        active = this.VALIDATION_STEP;
      } else if (
        this.canPassVehicleStep &&
        this.$idExist(this.estimate.vehicle?.id)
      ) {
        active = this.CUSTOMER_STEP;
      } else {
        active = this.VEHICLE_STEP;
      }
      return active;
    },
  },

  methods: {
    prev() {
      this.active = Math.max(this.active - 1, this.VEHICLE_STEP);
    },

    next() {
      const active = Math.min(this.active + 1, this.VALIDATION_STEP);

      this.active = active;
    },

    close() {
      this.$emit("onCloseEstimateModal");
    },

    switchToStep(step) {
      if (step == this.VALIDATION_STEP && !this.canPassCustomerStep) {
        return;
      }

      if (
        step == this.CUSTOMER_STEP &&
        (!this.canPassVehicleStep ||
          !this.$idExist(this.estimateBackup.vehicle?.id))
      ) {
        return;
      }

      this.active = step;
    },

    setActiveStep() {
      this.active = this.activeStep;
    },

    async passVehicleStep() {
      if (!this.canEditEstimate(this.estimate)) {
        this.next();
        return;
      }

      this.isLoading = true;
      swal.showLoading();

      try {
        await this.handleVehicleFormSubmit(this.estimate.vehicle);

        const estimateData = cloneDeep(this.estimate);

        if (!this.$idExist(estimateData.request?.id)) {
          delete estimateData.request;
        }

        if (!this.$idExist(estimateData.customer?.id)) {
          delete estimateData.customer;
        }

        if (!this.$idExist(estimateData.inspection?.id)) {
          delete estimateData.inspection;
        }

        if (!this.$idExist(estimateData.approver?.id)) {
          delete estimateData.approver;
        }

        await this.submitEstimateForm(estimateData);

        const estimate = await this.$store.getters["estimations/estimation"];

        if (this.$idExist(estimateData?.id)) {
          this.estimate = { ...estimateData, ...estimate };
          this.$emit("formChanged");
          this.next();
        } else {
          this.$emit("onEditEstimate", estimate);
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.isLoading = false;
        swal.close();
      }
    },

    async passCustomerStep() {
      if (!this.canEditEstimate(this.estimate)) {
        this.next();
        return;
      }

      this.isLoading = true;
      swal.showLoading();
      try {
        if (!this.$idExist(this.estimate.approver?.id)) {
          if (!!this.estimate.customer?.organization) {
            await this.handleCustomerFormSubmit(this.estimate.customer);
          }
          this.estimate.approver = null;
        } else {
          this.estimate.customer = null;
        }

        const estimateData = cloneDeep(this.estimate);

        if (!this.$idExist(estimateData.request?.id)) {
          delete estimateData.request;
        }

        if (!this.$idExist(estimateData.request?.id)) {
          delete estimateData.request;
        }

        if (!this.$idExist(estimateData.inspection?.id)) {
          delete estimateData.inspection;
        }

        await this.submitEstimateForm(estimateData);

        const estimate = await this.$store.getters["estimations/estimation"];

        this.estimate = { ...estimateData, ...estimate };

        this.$emit("formChanged");

        this.next();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.isLoading = false;
        swal.close();
      }
    },

    async ValidateEstimate() {
      this.isLoading = true;
      swal.showLoading();
      try {
        await this.submitEstimateForm(this.estimate);

        await this.$store.dispatch("estimations/valid", this.estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_VALIDATED"),
        });

        await this.getEstimate(this.estimate.id);

        this.$emit("onViewEstimate");
      } catch (error) {
        swal.close();
        await this.showRequestError(error, false);

        await this.getEstimate(this.estimate?.id);

        this.formErrors = this.refactorError(error.response?.data?.errors);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.setActiveStep();
      } finally {
        this.isLoading = false;
        this.showValidateModal = false;
      }
    },

    async cancelEstimate() {
      this.$emit("onCancelEstimate");
    },

    async submitEstimateForm(estimateData) {
      if (!estimateData.customer) {
        estimateData.customer = cloneDeep(estimateData.vehicle.customer);
      }
      if (!estimateData.approver) {
        estimateData.approver = cloneDeep(estimateData.vehicle.mecanic);
      }

      if (!this.$idExist(estimateData.customer?.id)) {
        estimateData.customer = null;
      }

      if (!this.$idExist(estimateData.approver?.id)) {
        estimateData.approver = null;
      }

      if (this.$idExist(estimateData?.id)) {
        await this.$store.dispatch("estimations/update", estimateData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_UPDATED"),
        });
      } else {
        delete estimateData.id;
        await this.$store.dispatch("estimations/add", estimateData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_ADDED"),
        });
      }
    },

    async handleVehicleFormSubmit(vehicleData) {
      vehicleData = { ...this.estimate.vehicle, ...vehicleData };
      let vehicle = null;

      if (!this.$idExist(vehicleData.customer?.id)) {
        delete vehicleData.customer;
      }
      if (!this.$idExist(vehicleData.mecanic?.id)) {
        delete vehicleData.mecanic;
      }

      if (this.$idExist(vehicleData?.id)) {
        delete vehicleData.organization;
        await this.$store.dispatch("vehicles/update", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      } else {
        delete vehicleData.id;
        await this.$store.dispatch("vehicles/add", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      }

      this.estimate.vehicle = { ...this.estimate.vehicle, ...vehicle };
      this.estimate.organization = {
        ...this.estimate.organization,
        ...vehicle.organization,
      };

      if (vehicleData.allowedLocations.length) {
        this.estimate.allowedLocations = cloneDeep(
          vehicleData.allowedLocations
        );
      }

      this.estimate.gallery = vehicle?.gallery;
      this.estimate.odometer = vehicle?.odometer;
      this.estimate.odometer_unit = vehicle?.odometer_unit;
      this.estimate.registration_number = vehicle?.registration_number;
      this.estimate.color = vehicle?.color;
    },

    async handleCustomerFormSubmit(customerData) {
      customerData = { ...this.estimate.customer, ...customerData };

      if (customerData.customer_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
      } else if (customerData.customer_type === CUSTOMER_TYPE_COMPANY) {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }

      let customer = null;

      if (this.$idExist(customerData?.id)) {
        if (this.$currentUserCan(this.$permissions.PERM_EDIT_CUSTOMERS)) {
          await this.$store.dispatch("customers/update", customerData);

          customer = await this.$store.getters["customers/customer"];

          this.estimate.customer = { ...this.estimate.customer, ...customer };
        }
      } else {
        if (this.$currentUserCan(this.$permissions.PERM_CREATE_CUSTOMERS)) {
          delete customerData.id;
          await this.$store.dispatch("customers/add", customerData);

          customer = await this.$store.getters["customers/customer"];

          this.estimate.customer = { ...this.estimate.customer, ...customer };
        }
      }
    },

    async getEstimate(estimateId) {
      await this.$store.dispatch("estimations/get", estimateId);
      const estimate = this.$store.getters["estimations/estimation"];
      this.estimate = { ...this.estimate, ...estimate };
    },

    refactorError(errors) {
      return errors.map((error) => {
        let err = { ...error };
        if (error?.source?.pointer === "/data") {
          if (
            error.detail.includes("customer") ||
            error.detail.includes("client")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/relationships/customer/data/id",
              },
            };
          }
          if (
            error.detail.includes("vehicle") ||
            error.detail.includes("véhicule")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/relationships/vehicle/data/id",
              },
            };
          }
          if (
            error.detail.includes("form") ||
            error.detail.includes("formulaire")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/relationships/estimationForm/data/id",
              },
            };
          }
        }
      });
    },

    onViewEstimate(estimate = null) {
      estimate = estimate ?? this.estimate;
      this.$emit("onViewEstimate", estimate);
    },

    onCloseEstimateModal() {
      this.$emit("onCloseEstimateModal");
    },
  },

  watch: {
    formErrors(errors) {
      this.formErrorsValue = cloneDeep(errors);
    },
    estimateData: {
      handler: function () {
        this.estimate = { ...this.estimateData };
        this.estimateBackup = { ...this.estimateData };
      },
      deep: true,
    },
    loading(value) {
      this.isLoading = value;
    },
  },
};
</script>
