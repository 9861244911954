<template>
  <div class="container-fluid estimates-global">
    <div class="page-title">
      <i class="fa-regular fa-circle-dollar"></i>
      <h1>{{ $t("COMMON.ESTIMATIONS") }}</h1>
    </div>

    <div class="list-table">
      <estimate-list-table
        :key="renderKey * 100"
        :filterCreator="selectedEstimationCreator"
        :filterStatus="selectedEstimationStatus"
        @onAddEstimate="openEstimateCreateModal"
        @onDeleteEstimate="deleteEstimate"
        @onEditEstimate="openEstimateEditModal"
        @onViewEstimate="openEstimateViewModal"
        show-in-progresses
        show-tabs
      />
    </div>

    <transition name="slide-right">
      <div
        v-if="isViewEstimateModalOpened"
        class="resizable-wrapper no-line"
        v-bind:class="[isViewEstimateModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ESTIMATE'"
          @onCloseModal="closeEstimateModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ESTIMATES.VIEW_ESTIMATE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openEstimate"
                :objectType="'estimations'"
                :objectId="openEstimate.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
                v-if="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openEstimateEditModal(openEstimate)"
                  v-if="canEditEstimate(openEstimate)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  @click="deleteEstimate(openEstimate)"
                  v-if="canDeleteEstimate(openEstimate)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="btn expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="closeEstimateModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-estimate-component
              v-if="openEstimate"
              :estimateId="openEstimate.id"
              @estimateLoaded="
                (estimate) => {
                  openEstimate = estimate;
                  openEstimateLoaded = true;
                }
              "
              @onViewEstimate="openEstimateViewModal"
              @onCancelEstimate="cancelEstimate"
              @onStartComponentsEstimate="startComponentsEstimate"
              @onEndComponentsEstimate="endComponentsEstimate"
              @onStartTimesEstimate="startTimesEstimate"
              @onEndTimesEstimate="endTimesEstimate"
              @onStartApproveEstimate="startApproveEstimate"
              @onEndApproveEstimate="endApproveEstimate"
              @onCloseEstimateModal="closeEstimateModal"
              @onEditEstimate="openEstimateEditModal"
              @onTakeOverComponentsEstimate="takeOverComponentsEstimate"
              @onTakeOverTimesEstimate="takeOverTimesEstimate"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isEditEstimateModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditEstimateModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ESTIMATE'"
          @onCloseModal="closeEstimateModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button class="back-button" @click="closeEstimateModal">
                <i class="fal fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("ESTIMATES.EDIT_ESTIMATE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="btn expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button
                class="close"
                @click="openEstimateViewModal(openEstimate)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-estimate-component
              v-if="openEstimate"
              :estimateId="openEstimate.id"
              :loading="loading"
              @onCancelEstimate="cancelEstimate"
              @onViewEstimate="openEstimateViewModal"
              @onCloseEstimateModal="closeEstimateModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isAddEstimateModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddEstimateModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ESTIMATE'"
          @onCloseModal="closeEstimateModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>{{ $t("ESTIMATES.ADD_ESTIMATE") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="btn expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="closeEstimateModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-estimate-component
              @onViewEstimate="openEstimateViewModal"
              @onEditEstimate="openEstimateEditModal"
              @onCloseEstimateModal="closeEstimateModal"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  ESTIMATE_STATUS_DRAFT,
  ESTIMATE_STATUS_APPROVED,
  ESTIMATE_STATUS_COMPLETED,
  ESTIMATE_STATUS_CANCELED,
  ESTIMATE_STATUS_REVIEWED,
  ESTIMATE_STATUS_DENIED,
  ESTIMATE_STATUS_PENDING,
  ESTIMATE_STATUS_IN_PROGRESS,
  ESTIMATE_STATUS_PENDING_COMPONENTS,
  ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS,
  ESTIMATE_STATUS_PENDING_TIMES,
  ESTIMATE_STATUS_IN_PROGRESS_TIMES,
  ESTIMATE_STATUS_PENDING_APPROVE,
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
  ESTIMATE_TYPES_STATUS_PENDING,
  ESTIMATE_TYPES_STATUS_IN_PROGRESS,
  ESTIMATE_TYPES_STATUS_COMPLETED,
} from "@/constants/estimates";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import EstimateListTable from "./partials/EstimateListTable.vue";
import EditEstimateComponent from "./components/EditEstimateComponent.vue";
import AddEstimateComponent from "./components/AddEstimateComponent.vue";
import ViewEstimateComponent from "./components/ViewEstimateComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EstimateListTable,
    NotificationSubscription,
    EditEstimateComponent,
    AddEstimateComponent,
    ViewEstimateComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  data() {
    const estimateId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewEstimateModalOpened = false;
    let isEditEstimateModalOpened = false;
    let isAddEstimateModalOpened = false;
    let openEstimate = null;
    if (estimateId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewEstimateModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditEstimateModalOpened = true;
      }
      openEstimate = { id: estimateId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddEstimateModalOpened = true;
    }
    return {
      query: null,
      isViewEstimateModalOpened: isViewEstimateModalOpened,
      isEditEstimateModalOpened: isEditEstimateModalOpened,
      isAddEstimateModalOpened: isAddEstimateModalOpened,
      openEstimate: openEstimate,
      openEstimateLoaded: false,
      renderKey: 1,
      ESTIMATE_STATUS_DRAFT,
      ESTIMATE_STATUS_COMPLETED,
      ESTIMATE_STATUS_PENDING_COMPONENTS,
      ESTIMATE_STATUS_CANCELED,
      ESTIMATE_STATUS_APPROVED,
      ESTIMATE_STATUS_REVIEWED,
      ESTIMATE_STATUS_DENIED,
      ESTIMATE_STATUS_PENDING_TIMES,
      ESTIMATE_STATUS_PENDING_APPROVE,
      ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS,
      ESTIMATE_STATUS_IN_PROGRESS_TIMES,
      ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
      ESTIMATE_STATUS_PENDING,
      ESTIMATE_STATUS_IN_PROGRESS,
      ESTIMATE_TYPES_STATUS_PENDING,
      ESTIMATE_TYPES_STATUS_IN_PROGRESS,
      ESTIMATE_TYPES_STATUS_COMPLETED,
      selectedEstimationStatus: null,
      selectedEstimationCreator: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    openEstimateCreateModal() {
      this.closeEstimateModal();
      this.isAddEstimateModalOpened = true;
      this.openEstimateLoaded = false;

      this.$router.replace({
        name: "List Estimates",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },

    openEstimateViewModal(estimate, reRender = false) {
      if (this.canEditEstimate(estimate)) {
        this.openEstimateEditModal(estimate);
      }

      this.closeEstimateModal();
      this.openEstimate = estimate;
      this.isViewEstimateModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Estimates",
        query: { id: this.openEstimate.id, action: QUERY_ACTIONS_VIEW },
      });
    },

    openEstimateEditModal(estimate) {
      if (!this.canEditEstimate(estimate)) {
        this.openEstimateViewModal(estimate);
      }

      this.closeEstimateModal();
      this.openEstimate = estimate;
      this.isEditEstimateModalOpened = true;

      this.$router.replace({
        name: "List Estimates",
        query: { id: this.openEstimate.id, action: QUERY_ACTIONS_EDIT },
      });
    },

    closeEstimateModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      if (
        this.isAddEstimateModalOpened ||
        this.isViewEstimateModalOpened ||
        this.isEditEstimateModalOpened
      ) {
        this.renderKey++;
      }
      this.isAddEstimateModalOpened = false;
      this.isViewEstimateModalOpened = false;
      this.isEditEstimateModalOpened = false;
      this.openEstimateLoaded = false;
      this.openEstimate = null;

      if (
        this.$route.name !== "List Estimates" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Estimates",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    isEstimateFillComplete(estimate) {},

    async deleteEstimate(estimate) {
      const confirmation = await swal.fire({
        title: this.$t("ESTIMATES.DELETE_THIS_ESTIMATE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("estimations/destroy", estimate.id);
          this.renderKey++;
          this.closeEstimateModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("ESTIMATES.ESTIMATE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    changeSelectedStatus(status, creator = null) {
      this.selectedEstimationStatus = status;
      this.selectedEstimationCreator = creator;
    },

    canValidEstimate(estimate) {
      return (
        !!estimate &&
        !!estimate.vehicle &&
        this.$currentUserCan(this.$permissions.PERM_VALID_ESTIMATIONS) &&
        estimate.status == ESTIMATE_STATUS_DRAFT
      );
    },

    canStartComponentsEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(
          this.$permissions.PERM_EDIT_ESTIMATIONS_COMPONENTS
        ) &&
        [ESTIMATE_STATUS_IN_PROGRESS, ESTIMATE_STATUS_PENDING].includes(
          estimate.status
        ) &&
        estimate.components_status != ESTIMATE_TYPES_STATUS_IN_PROGRESS
      );
    },

    canEndComponentsEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(
          this.$permissions.PERM_EDIT_ESTIMATIONS_COMPONENTS
        ) &&
        [ESTIMATE_STATUS_IN_PROGRESS, ESTIMATE_STATUS_PENDING].includes(
          estimate.status
        ) &&
        estimate.components_status == ESTIMATE_TYPES_STATUS_IN_PROGRESS
      );
    },

    canStartTimesEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_ESTIMATIONS_TIMES) &&
        [ESTIMATE_STATUS_IN_PROGRESS, ESTIMATE_STATUS_PENDING].includes(
          estimate.status
        ) &&
        estimate.times_status != ESTIMATE_TYPES_STATUS_IN_PROGRESS
      );
    },

    canEndTimesEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_ESTIMATIONS_TIMES) &&
        [ESTIMATE_STATUS_IN_PROGRESS, ESTIMATE_STATUS_PENDING].includes(
          estimate.status
        ) &&
        estimate.times_status == ESTIMATE_TYPES_STATUS_IN_PROGRESS
      );
    },

    canStartApproveEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_APPROVE_ESTIMATIONS) &&
        estimate.status == ESTIMATE_STATUS_PENDING_APPROVE
      );
    },

    canEndApproveEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_APPROVE_ESTIMATIONS) &&
        estimate.status == ESTIMATE_STATUS_IN_PROGRESS_APPROVE
      );
    },

    canEditEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_ESTIMATIONS) &&
        estimate.status == ESTIMATE_STATUS_DRAFT
      );
    },

    canDeleteEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_DELETE_ESTIMATIONS) &&
        estimate.status == ESTIMATE_STATUS_DRAFT
      );
    },

    canCancelEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_CANCEL_ESTIMATIONS) &&
        ![ESTIMATE_STATUS_COMPLETED, ESTIMATE_STATUS_CANCELED].includes(
          estimate.status
        )
      );
    },

    async validEstimate(estimate) {
      const confirmation = await swal.fire({
        title: this.$t("ESTIMATES.VALID_ESTIMATE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.isDismissed) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("estimations/valid", estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_APPROVED"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async startComponentsEstimate(estimate) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/startComponents", estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_STARTED_COMPONENTS"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async endComponentsEstimate(estimate) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/endComponents", estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_ENDED_COMPONENTS"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async takeOverComponentsEstimate(estimate, data) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/takeOverComponents", {
          ...data,
          estimationId: estimate.id,
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.COMPONENTS_TOOK_OVER"),
        });

        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async takeOverTimesEstimate(estimate, data) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/takeOverTimes", {
          ...data,
          estimationId: estimate.id,
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.TIMES_TOOK_OVER"),
        });

        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async startTimesEstimate(estimate) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/startTimes", estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_STARTED_TIMES"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async endTimesEstimate(estimate) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/endTimes", estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_ENDED_TIMES"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async startApproveEstimate(estimate) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/startApprove", estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_STARTED_APPROVE"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async endApproveEstimate(estimate) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/endApprove", estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_ENDED_APPROVE"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error, false);
        this.renderKey++;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async cancelEstimate(estimate) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("estimations/cancel", estimate.id);
        // this.renderKey++;
        this.openEstimateViewModal(estimate, true);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_CANCELED"),
        });
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {},
};
</script>
